window.sendFBEvent = async (event_name, options = {}) => {
  const eventID = Date.now() + "-" + Math.floor(Math.random() * 100000000000)

  let user_data = {}

  const { userEmailHashed, userPhoneHashed, userUID } = window.config

  const isEmpty = (value) => [null, undefined, ""].includes(value)

  if (!isEmpty(userUID)) user_data["external_id"] = userUID
  if (!isEmpty(userEmailHashed)) user_data["em"] = userEmailHashed
  if (!isEmpty(userPhoneHashed)) user_data["ph"] = userPhoneHashed

  const data = {
    event: {
      event_name,
      event_id: eventID,
      user_data,
      ...options,
    },
  }

  try {
    fbq(
      "track",
      event_name,
      {
        user_data,
        ...options,
      },
      { eventID },
    )
  } catch {}

  console.log("Trigger conversions API")
}

const evenHandler = () => {
  window.sendFBEvent("Subscribe")
}

document.addEventListener("turbo:load", () => {
  const forms = document.querySelectorAll(".newsletter-form")

  forms.forEach((form) => {
    form.addEventListener("submit", evenHandler)

    document.addEventListener("turbo:before-cache", () => {
      form.removeEventListener("submit", evenHandler)
    })
  })
})
