import componentInitializer from "@/frontend/utils/componentInitializer"
import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"
import { i18n } from "@/lib/vue/plugins/i18n"

function createVueComponent(target, asyncComponent) {
  const el = document.getElementById(target)

  if (el) {
    new Vue({
      el,
      store,
      apolloProvider,
      i18n,
      render: (h) => h(asyncComponent),
    })
  }
}

componentInitializer(() => {
  createVueComponent(
    "favourite-drawer-portal",
    defineAsyncComponent(() => import("@/components/FavouriteDrawerPortal.vue")),
  )
  createVueComponent(
    "express-checkout-portal",
    defineAsyncComponent(() => import("@/components/ExpressCheckoutPortal.vue")),
  )
})
